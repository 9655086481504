<template>
  <div class="cancelSheet">
    <el-dialog title="取消答题卡" :visible.sync="dialogVisible" width="413px">
      <div>
        当前正在取消
        <span style="color: #fe5f59">{{ indexSubject.label }}</span>
        科目答题卡，是否继续?
      </div>
      <el-checkbox
        v-model="ifRetain"
        :disabled="indexSubject.ifCover == 0"
        style="margin-top: 8px"
      >
        保留已上传试题
      </el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { exampapersheetCancel } from "@/core/api/exam/sheet";
export default {
  name: "CancelSheet",
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      ifRetain: true,
      indexSubject: {},
    };
  },
  created() {},
  methods: {
    submit() {
      let data = {
        examPaperId: this.indexSubject.value,
        sheetUuid: this.indexSubject.sheetUuid,
        ifRetain: this.ifRetain,
      };
      exampapersheetCancel(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "取消成功！",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$parent.getObj();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init(indexSubject) {
      this.ifRetain = true;
      this.dialogVisible = true;
      this.indexSubject = JSON.parse(JSON.stringify(indexSubject));
    },
  },
};
</script>
<style lang="scss" scoped>
.cancelSheet {
}
</style>
