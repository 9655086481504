<template>
  <div class="ExamTemplate">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      router-url="examlist"
      :query="{ examId: $route.query.examId }"
      :name="examInformation.name + ' / '"
    >
    </ExamDetailsHeader>

    <div class="paper-list-box">
      <div class="paper-item">
        <template v-for="(v, i) in paperList">
          <div
            v-if="getTabShow(1, v.subjectId)"
            :key="i"
            class="paper-list"
            :class="{ on: indexSubjectId == v.subjectId }"
            @click="checkSubject(v)"
          >
            {{ v.label }}
          </div>
        </template>
      </div>

      <div>
        <el-button type="primary" plain @click="createThree">
          <template v-if="indexSubject.sheetUuid">更换三方答题卡</template>
          <template v-else>新建三方答题卡</template>
        </el-button>
        <el-button type="primary" plain @click="showReuse()">
          答题卡复用
        </el-button>
        <el-button type="primary" plain @click="showExport()">
          导入答题卡
        </el-button>
        <el-button
          type="primary"
          :loading="exportBtnLoading"
          plain
          @click="exportFile"
        >
          导出答题卡
        </el-button>
      </div>
    </div>
    <div class="paper-question-box">
      <div class="paper-img-box">
        <div v-if="indexSubject.sheetUuid" class="paper-img-header">
          <div
            v-for="(v, i) in sheetMarkList"
            :key="i"
            class="sheet-list"
            :class="{ 'check-on': v.urlAdjust == indexMarkList.urlAdjust }"
            @click="checkSheet(v)"
          >
            {{ i + 1 }}
          </div>
          <div class="btn-sheet-box">
            <el-button
              v-if="indexSubject.sheetType == 0 && $route.query.password"
              type="text"
              @click="goSheet"
            >
              <img src="@/assets/newExam/sheet/icon_edit_sheet.png" alt="" />
              <img
                class="on"
                src="@/assets/newExam/sheet/icon_edit_sheet.png"
                alt=""
              />
              修改自制卡模板
            </el-button>
            <el-button style="right: 141px" type="text" @click="goSheetT()">
              <img src="@/assets/newExam/sheet/icon_edit_sheet.png" alt="" />
              <img
                class="on"
                src="@/assets/newExam/sheet/icon_edit_sheet.png"
                alt=""
              />
              修改三方模板
            </el-button>
            <el-button type="text" style="color: #fe5f59" @click="cancelSheet">
              <img src="@/assets/newExam/sheet/icon_del.png" alt="" />
              <img
                class="on"
                src="@/assets/newExam/sheet/icon_del.png"
                alt=""
              />
              取消答题卡
            </el-button>
          </div>

          <!-- <div class="sheet-list">2</div> -->
        </div>
        <template v-if="sheetMarkList.length > 0">
          <div ref="markSheet" class="sheet-img">
            <!-- <img :src="indexMarkList.urlAdjust" alt="" /> -->
            <canvas id="imgCanvas" ref="imgCanvas"></canvas>
            <canvas id="lineCanvas" ref="lineCanvas"> </canvas>
          </div>
        </template>

        <div v-if="sheetMarkList.length == 0" class="scan-none-list">
          <img src="@/static/scanSheet/default-diagram.png" alt="" />
          <p>该试卷未绑定模板</p>
        </div>
      </div>
      <div class="peper-question-list-box">
        <div class="peper-question-header">
          <span class="paper-header"> 答题卡分数设置 {{ scoreAll }}分 </span>
          <span class="tips">点击下方输入框中的空隙可拖动试题顺序</span>
        </div>
        <div class="objective-list-box">
          <p>
            客观题（{{
              questionList.filter((v) => v.type != 1).length
            }}个小题，共{{ objectiveScore }}分）
            <span>
              <el-tooltip
                class="item"
                effect="dark"
                content="自动排序只能支持纯数字,客观题排序"
                placement="top-start"
              >
                <el-button
                  type="text"
                  icon="el-icon-warning-outline"
                  @click="sortList"
                  >自动排序</el-button
                >
              </el-tooltip>
            </span>
          </p>
          <div class="qustion-order-list-item">
            <div>题号</div>
            <div>分数</div>
            <div>漏选得分</div>
            <div>类型</div>
          </div>
          <SlickList
            v-model="objQuestionList"
            :lock-to-container-edges="true"
            class="edit-scroll-style qustion-order-list-box"
            lock-axis="y"
            :distance="0.5"
            @input="getChangeLists"
          >
            <SlickItem
              v-for="(v, index) in objQuestionList"
              :key="index"
              :index="index"
              class="qustion-order-list-item"
            >
              <el-input
                v-model="v.examQuestionNumber"
                placeholder="请输入内容"
              ></el-input>
              <el-input v-model="v.score" placeholder="请输入内容"></el-input>
              <el-input
                v-model="v.scoreMiss"
                placeholder="请输入内容"
              ></el-input>

              <el-select
                v-model="v.selectType"
                placeholder="请选择"
                style="width: 74px"
                :disabled="v.selectType == 3"
              >
                <el-option label="单选" :value="1"> </el-option>
                <el-option label="多选" :value="2"> </el-option>
                <el-option label="判断题" :value="3" disabled> </el-option>
              </el-select>
            </SlickItem>
          </SlickList>
          <div class="btn-box">
            <el-button
              type="primary"
              :loading="btnLoading"
              :disabled="questionList.length == 0"
              @click="save()"
            >
              保存
              <i class="el-icon-edit el-icon--right"></i>
            </el-button>
          </div>
        </div>
        <div class="objective-list-box">
          <p>
            <span>
              主观题（{{
                questionList.filter((v) => v.type == 1).length
              }}个小题， 共{{ subjectiveScore }}分）
            </span>
            <span>
              <el-button type="text" icon="el-icon-edit" @click="editAwser()">
                调整区域
              </el-button>
              <el-button type="text" @click="editBtn(1)">
                <img src="@/assets/newExam/sheet/icon_split.png" alt="" />
                <img
                  class="on"
                  src="@/assets/newExam/sheet/icon_split.png"
                  alt=""
                />
                拆分
              </el-button>
              <el-button type="text" @click="editBtn(2)">
                <img src="@/assets/newExam/sheet/icon_merge.png" alt="" />
                <img
                  class="on"
                  src="@/assets/newExam/sheet/icon_merge.png"
                  alt=""
                />
                合并
              </el-button>
            </span>
          </p>
          <div class="qustion-order-list-item">
            <div>题号</div>
            <div>分数</div>
            <!-- <div>漏选得分</div>  objQuestionList subQuestionList-->
          </div>
          <SlickList
            v-model="subQuestionList"
            :lock-to-container-edges="true"
            class="edit-scroll-style qustion-order-list-box"
            lock-axis="y"
            :distance="0.5"
            @input="getChangeLists"
          >
            <SlickItem
              v-for="(v, index) in subQuestionList"
              :key="index"
              :index="index"
              :class="{ 'hidden-list': !showScore(v) }"
              class="sub-qustion-order-list"
            >
              <div class="question-box-list">
                <el-input
                  v-model="v.examQuestionNumber"
                  placeholder="请输入题号"
                ></el-input>
                <el-input v-model="v.score" placeholder="请输入分数"></el-input>
              </div>
              <template v-if="v.isOption">
                <template v-for="(item, indexk) in subQuestionList">
                  <div
                    v-if="
                      !showScore(item) &&
                      item.examQuestionGroup == v.examQuestionGroup
                    "
                    :key="indexk"
                    class="question-box-list"
                  >
                    <el-input
                      v-model="item.examQuestionNumber"
                      placeholder="请输入题号"
                    ></el-input>
                    <!-- {{ item.examQuestionNumber }} -->
                  </div>
                </template>
              </template>
            </SlickItem>
          </SlickList>
          <div class="btn-box">
            <el-button
              type="primary"
              :loading="btnLoading"
              :disabled="questionList.length == 0"
              @click="save()"
            >
              保存
              <i class="el-icon-edit el-icon--right"></i>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisibleTips" width="400px">
      <div>当前学科已上传模板，是否使用已存在项？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goLink">新 建</el-button>
        <el-button type="primary" @click="dialogVisibleTips = false"
          >应 用</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="答题卡导入"
      :visible.sync="batchAmendingVisible"
      width="624px"
      class="self-amending-form"
    >
      <div class="upload-tips">
        确定导入答题卡至 {{ examInformation.examName }}-{{
          indexSubject.label
        }}?
      </div>
      <div class="err">
        注意：只能导入由系统导出的 .json 文件，否则将无法转换成功！
      </div>
      <el-upload
        ref="uploadFile"
        class="upload-file"
        drag
        :action="fileURL"
        :headers="uploadHeaders"
        accept=".json"
        :auto-upload="false"
        :before-upload="handleImportBefore"
        :on-success="batchModifyOnSuccess"
        :on-error="batchModifyOnError"
        :on-progress="batchModifyOnProgress"
        :data="wordData"
        :limit="1"
        :file-list="fileList"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或点击上传</div>
      </el-upload>
      <!-- <div></div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dataFormSubmit()">导 入</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="答题卡复用"
      :visible.sync="reuseDingVisible"
      width="586px"
    >
      <div>
        <div class="select-list">
          <p v-if="paperList.length > 0">
            科目：{{
              paperList.filter((item) => item.subjectId == indexSubjectId)[0]
                .label
            }}&nbsp;&nbsp;&nbsp;
          </p>
        </div>
        <div class="select-list">
          <p>答题卡类型：</p>
          <el-select
            v-model="searchSheet.type"
            placeholder="请选择"
            @change="checkPage()"
          >
            <el-option
              v-for="item in sheetTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-list">
          <p>答题卡学段：</p>
          <el-select
            v-model="searchSheet.levelId"
            placeholder="请选择"
            @change="checkPage()"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-list">
          <p>入学年份：</p>

          <el-select
            v-model="searchSheet.year"
            placeholder="请选择"
            clearable
            @change="checkPage"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-list">
          <p>阅卷类型：</p>
          <el-select
            v-model="searchSheet.markType"
            placeholder="请选择"
            clearable
            @change="checkPage"
          >
            <el-option
              v-for="item in createMarkTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-list">
          <p>选择模板：</p>
          <el-select
            v-model="sheetUuid"
            class="name-select"
            placeholder="请选择"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in sheetIdList"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-list">
          <el-checkbox v-model="ifCover">同步试题(覆盖已有试题)</el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reuseDingVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="setPaperUuid"
          >使 用</el-button
        >
      </span>
    </el-dialog>
    <!-- 分数修改列表 -->
    <el-dialog title="提示" :visible.sync="scopeTips" width="400px">
      <div>
        <div v-for="(v, i) in questionEditList" :key="i">
          <span>"{{ v.examQuestionNumber }}"</span>
          修改分值:{{ v.newScore }}分
          <i class="el-icon-right"></i>
          {{ v.score }}分
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scopeTips = false">取 消</el-button>
        <el-button type="primary" @click="getQuesstionRole()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 总分非常规提示 -->
    <el-dialog title="总分" :visible.sync="scopeNumTips" width="400px">
      <div>{{ `当前分数为${scoreSum}分,为非常规分数,是否保存?` }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scopeNumTips = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="editQuestion()">
          确定
        </el-button>
      </span>
    </el-dialog>
    <!-- 拆分合并小题 -->
    <el-dialog
      :title="indexSplitType == 1 ? '拆分小题' : '合并小题'"
      :visible.sync="splitDialogVisible"
      width="720px"
      class="split-question"
    >
      <div v-if="indexSplitType == 1">
        <span class="split-title">请选择题目进行拆分</span>
        <el-radio-group v-model="splitQuestion" class="edit-scroll-style">
          <template v-for="(v, i) in questionList">
            <el-radio
              v-if="v.selectType == 0 && v.isOption != 1"
              :key="i"
              :label="v.examQuestionNumber"
              :disabled="v.markPointId > 0"
            >
              {{ v.examQuestionNumber }}
            </el-radio>
          </template>
        </el-radio-group>
        <p>
          将小题拆分成
          <el-input
            v-model="splitQuestionNum"
            placeholder=""
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="splitQuestionNum = $event.target.value"
          ></el-input>
          道小题
        </p>
      </div>
      <div v-else>
        <span class="split-title">请选择题目进行合并</span>
        <el-checkbox-group v-model="checkList" @change="checkQuestion">
          <template v-for="(v, i) in questionList">
            <el-checkbox
              v-if="v.selectType == 0 && v.isOption != 1"
              :key="i"
              :label="v.examQuestionNumber"
              :disabled="v.markPointId > 0"
            >
              {{ v.examQuestionNumber }}</el-checkbox
            >
          </template>
        </el-checkbox-group>
        <p>
          小题名称：
          <el-input
            v-model="splitQuestionNum"
            placeholder=""
            style="width: 220px"
          ></el-input>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="splitDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goSplitQuestion()"> 确 定 </el-button>
      </span>
    </el-dialog>
    <!-- 调整区域 -->
    <el-dialog
      title="调整区域"
      :visible.sync="editDialogVisible"
      width="720px"
      class="split-question"
    >
      <div>
        <span class="split-title">
          请选择需要调整区域的试题，调整区域后会重新裁切试题，请谨慎操作！
        </span>
        <!--       @change="checkAre" -->
        <el-radio-group
          v-model="splitQuestion"
          class="edit-scroll-style"
          style="display: block"
        >
          <template v-for="(v, i) in questionList">
            <el-radio
              v-if="getshowQuestion(v)"
              :key="i"
              :label="v.examQuestionNumber"
            >
              {{ v.examQuestionNumber }}
            </el-radio>
          </template>
        </el-radio-group>
        <!-- <div v-if="indexQuesiton.id">
          <el-button
            v-if="
              indexQuesiton.extendList && indexQuesiton.extendList.length > 0
            "
            type="text"
            @click="showAre(0)"
          >
            调整第1部分
          </el-button>
          <el-button v-else type="text" @click="showAre(0)">
            调整区域
          </el-button>
          <template v-if="indexQuesiton.extendList">
            <el-button
              v-for="(v, i) in indexQuesiton.extendList"
              :key="i"
              type="text"
              @click="showAre(i)"
            >
              调整第{{ i + 2 }}部分
            </el-button>
          </template>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goSplitQuestion()"> 调 整 </el-button>
      </span>
    </el-dialog>
    <changeSheet ref="changeSheet"></changeSheet>
    <cancelSheet ref="cancelSheet"></cancelSheet>
  </div>
</template>

<script>
import {
  exampapersheetdetailV2page,
  exampapersheetdetailUpdateV2list,
  exampapersheetmarkList,
  exampapersheetExport,
  exampapersheetPage,
  saveExampapersheetmark,
  exampapersheetDuplicate,
} from "@/core/api/exam/sheet";
import ExamDetailsHeader from "@/components/examinationHeader";
import cancelSheet from "./components/cancelSheet";
import { getObj } from "@/core/api/exam/exam";
import { fileDownloadByUrl } from "@/core/util/util";
import { getStore, removeStore } from "@/core/util/store";
import { markTypeList } from "@/core/util/constdata";
import { SlickList, SlickItem } from "vue-slicksort";
import { levelOptions } from "@/core/util/constdata";
import changeSheet from "./components/changeSheet.vue";
import { getYear } from "@/core/util/util";
import { createMarkTypeList } from "@/core/util/sheetPaper";
// import { json } from "body-parser";
export default {
  name: "ExamTemplate",
  components: {
    ExamDetailsHeader,
    cancelSheet,
    SlickItem,
    SlickList,
    changeSheet,
  },
  data() {
    return {
      createMarkTypeList: createMarkTypeList,
      yearList: getYear(),
      levelOptions: levelOptions(),
      // 调整区域
      indexQuesiton: {},
      editDialogVisible: false,
      ifCover: false,
      editQuestionVal: "",
      // 拆分 合并
      checkList: [],
      indexSplitType: 1,
      splitDialogVisible: false,
      splitQuestion: "",
      splitQuestionNum: 2,
      // 拆分 合并
      scoreSum: 0,
      questionEditList: [],
      sheetTypeList: [
        {
          value: "",
          label: "全部类型",
        },
        {
          value: 0,
          label: "网页制卡",
        },
        {
          value: 1,
          label: "裁切卡",
        },
        {
          value: 2,
          label: "题库卡",
        },
      ],
      fileList: [],
      //  subQuestionList objQuestionList
      sheetIdList: [],
      subQuestionList: [],
      objQuestionList: [],
      loading: false,
      scopeNum: 0,
      scopeNumTips: false,
      scopeTips: false,
      btnLoading: false,
      // 答题卡复用弹框
      reuseDingVisible: false,
      sheetUuid: "",
      searchSheet: {
        type: "",
        year: "",
        levelId: "",
        markType: "",
        subjectId: "",
        name: "",
      },
      markTypeList: markTypeList,
      // 答题卡导入
      batchAmendingVisible: false,
      examInformation: {
        examName: "",
      },
      indexSubject: {},
      paperList: [],
      indexSubjectId: 0,
      questionList: [],
      sheetMarkList: [],
      indexMarkList: {},
      dialogVisibleTips: false,
      exportBtnLoading: false,
      fileURL: "/exam/exampapersheet/import",
      wordData: {},
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      indexPaperId: "",
    };
  },
  computed: {
    subjectiveScore() {
      let score = 0;
      // let quesitonGroupArrStr = "";
      this.questionList.forEach((index) => {
        if (index.type == 1) {
          let tempScore = Number(index.score);
          if (index.isOption == 1) {
            tempScore = 0;
            let groupList = this.questionList.filter(
              (i) => index.examQuestionGroup == i.examQuestionGroup
            );
            groupList.map((item, i) => {
              if (
                index.examQuestionSheetValue == item.examQuestionSheetValue &&
                i == 0
              ) {
                tempScore = Number(index.score);
              }
            });
          }
          score = Number(score) + tempScore;
        }
      });
      let scoreStr = score.toFixed(1);
      if (scoreStr.split(".")[1] == 0) {
        scoreStr = scoreStr.split(".")[0];
      }
      return scoreStr;
    },
    objectiveScore() {
      let score = 0;
      this.questionList.forEach((index) => {
        if (index.type != 1) {
          score = Number(score) + Number(index.score);
        }
      });
      let scoreStr = score.toFixed(1);
      if (scoreStr.split(".")[1] == 0) {
        scoreStr = scoreStr.split(".")[0];
      }
      return scoreStr;
    },
    scoreAll() {
      let quesitonGroupArrStr = "";
      let score = 0;
      this.questionList.forEach((index) => {
        let tempScore = Number(index.score);
        if (index.isOption == 1) {
          if (quesitonGroupArrStr.indexOf("," + index.examQuestionGroup) > -1) {
            tempScore = 0;
          }
          quesitonGroupArrStr += "," + index.examQuestionGroup;
        }
        score = Number(score) + tempScore;
      });
      let scoreStr = score.toFixed(1);
      if (scoreStr.split(".")[1] == 0) {
        scoreStr = scoreStr.split(".")[0];
      }
      return scoreStr;
    },
  },
  created() {
    // this.examInformation.examName = this.$route.query.examName;
    this.getObj();
  },
  methods: {
    getTabShow(key, subjectId) {
      let type = false;
      let userInfo = getStore({ name: "userInfo" });
      if (
        this.examInformation.examWorkList &&
        this.examInformation.examWorkList.length > 0
      ) {
        this.examInformation.examWorkList.forEach((item) => {
          if (item.subjectType == key && item.subjectId == subjectId) {
            item.teacherList.map((index) => {
              if ((index.uesrId = userInfo.id)) {
                type = true;
              }
            });
          }
        });
      }
      if (userInfo.roleId != 5) {
        type = true;
      }
      return type;
    },
    showAre() {},
    checkAre() {
      // console.log(this.sheetMarkList);
      let markSubjective = new Array();
      this.sheetMarkList.map((item) => {
        let indexMarkSubjective = new Array();
        // 主观题数组
        if (!item.markSubjective) {
          indexMarkSubjective = [];
        } else {
          indexMarkSubjective = JSON.parse(item.markSubjective);
        }
        markSubjective = markSubjective.concat(indexMarkSubjective);
      });
      this.indexQuesiton = {};
      markSubjective.map((index) => {
        if (!index.questionNumList) return;
        index.questionNumList.map((item) => {
          if (item.examQuestionNumber == this.editQuestionVal) {
            this.indexQuesiton = index;
          }
        });
      });
    },
    goEditQuestion() {
      // console.log(this.editQuestionVal);
      this.indexQuesiton;
      // this.sheetMarkList.map()
    },
    editAwser() {
      this.editDialogVisible = true;
      this.indexSplitType = 3;
      this.editQuestionVal = "";
      // console.log(this.sheetMarkList);
    },
    checkQuestion() {
      this.splitQuestionNum = this.checkList.join("-");
    },
    editBtn(type) {
      this.splitDialogVisible = true;
      this.indexSplitType = type;
      this.splitQuestionNum = 2;
      this.splitQuestion = "";
      this.checkList = [];
      if (type == 2) {
        this.splitQuestionNum = "";
      }
    },
    getshowQuestion(v) {
      let type = true;
      if (v.selectType > 0) {
        type = false;
      }
      // 选做题只显示第一小题题号
      // if (!this.showScore(v)) {
      //   type = false;
      // }
      if (v.isOption == 1) {
        type = false;
      }
      return type;
    },
    async goSplitQuestion() {
      if (this.splitQuestionNum < 2 && this.indexSplitType == 1) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "拆分试题至少是两道题！",
        });
        return;
      }
      if (!this.splitQuestion && this.indexSplitType == 1) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请选择需要拆分的试题",
        });
        return;
      }
      if (!this.splitQuestion && this.indexSplitType == 3) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请选择需要调整的试题",
        });
        return;
      }
      if (!this.splitQuestionNum && this.indexSplitType == 2) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请输入试题名称！",
        });
        return;
      }
      if (this.checkList.length == 0 && this.indexSplitType == 2) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请选择需要合并的试题",
        });
        return;
      }
      removeStore({ name: "question_split_info", type: 1 });
      let data = Object.assign({}, this.$route.query);
      data.splitQuestion = this.splitQuestion;
      data.splitQuestionNum = this.splitQuestionNum;
      // data.examName = this.examInformation.examName;
      data.type = this.indexSplitType;
      data.maxNumber = 0;
      await new Promise((resolve) => {
        this.questionList.map((item, index) => {
          if (item.examQuestionSheetValue >= data.maxNumber) {
            data.maxNumber = item.examQuestionSheetValue + 1;
          }
          if (index == this.questionList.length - 1) {
            resolve();
          }
        });
      });
      // data.maxNumber=examQuestionSheetValue
      // console.log();
      if (this.indexSplitType == 3) {
        data.splitQuestionNum = this.splitQuestion;
        data.splitQuestion = this.splitQuestion;
        this.questionList.map((item) => {
          if (item.examQuestionNumber == data.splitQuestion) {
            data.markPointId = item.markPointId;
            data.id = item.id;
          }
        });
        data.paperId = this.paperList.filter(
          (i) => i.subjectId == this.indexSubjectId
        )[0].value;
      }
      data.sheetUuid = this.paperList.filter(
        (item) => item.subjectId == this.indexSubjectId
      )[0].sheetUuid;
      if (this.indexSplitType == 1 || this.indexSplitType == 3) {
        data.score = this.questionList.filter(
          (item) => item.examQuestionNumber == this.splitQuestion
        )[0].score;
      }
      if (this.indexSplitType == 2) {
        data.splitQuestion = this.checkList.join(",");
        data.score = 0;
        this.questionList.map((item) => {
          if (this.checkList.indexOf(item.examQuestionNumber) != -1) {
            data.score = Number(data.score) + Number(item.score);
          }
        });
      }

      this.$router.push({
        name: "TemplateSplit",
        query: data,
      });
    },
    dataFormSubmit() {
      this.$refs.uploadFile.submit();
    },
    sortList() {
      // console.log("111111");
      // let arr = JSON.parse(JSON.stringify(this.questionList));
      this.objQuestionList = this.objQuestionList.sort(function (a, b) {
        return Number(a.examQuestionNumber) - Number(b.examQuestionNumber);
      });
      this.objQuestionList.map((item, index) => {
        item.examQuestionSheetOrder = index + 1;
      });
      this.subQuestionList.map((item, index) => {
        item.examQuestionSheetOrder = index + 1 + this.objQuestionList.length;
      });
      this.questionList = new Array()
        .concat(this.objQuestionList)
        .concat(this.subQuestionList);
      this.$message({
        showClose: true,
        type: "success",
        message: "排序成功,如果需要应用客观题顺序,请点击下方保存!",
      });
      // console.log(this.questionList);
    },
    // 修改
    setJson() {
      // console.log(this.indexMarkList);
      this.sheetMarkList.map((item) => {
        let markObjective = item.markObjective || "[]";
        let markSubjective = item.markSubjective || "[]";
        markObjective = JSON.parse(markObjective);
        markSubjective = JSON.parse(markSubjective);
        let markObjectiveType = false;
        let markSubjectiveType = false;

        this.questionList.forEach((index) => {
          // 客观题
          markObjective.forEach((itemList) => {
            if (!itemList.questionNumList) {
              return;
            }

            itemList.questionNumList.map((i) => {
              if (i.value == index.examQuestionSheetValue) {
                if (
                  i.score != index.score ||
                  i.examQuestionNumber != index.examQuestionNumber ||
                  i.scoreMiss != index.scoreMiss
                ) {
                  markObjectiveType = true;
                }
                i.score = index.score;
                i.examQuestionNumber = index.examQuestionNumber;
                if (index.scoreMiss) {
                  i.scoreMiss = index.scoreMiss;
                }
              }
            });
          });
          // 主观题
          markSubjective.forEach((itemList) => {
            if (!itemList.questionNumList) {
              return;
            }

            itemList.questionNumList.map((i) => {
              if (i.value == index.examQuestionSheetValue) {
                if (
                  i.score != index.score ||
                  i.examQuestionNumber != index.examQuestionNumber ||
                  (i.scoreMiss && i.scoreMiss != index.scoreMiss)
                ) {
                  markSubjectiveType = true;
                }
                i.score = index.score;
                i.examQuestionNumber = index.examQuestionNumber;
                if (i.scoreMiss) {
                  i.scoreMiss = index.scoreMiss;
                }
              }
            });
          });
        });

        if (markObjectiveType) {
          let data = {
            examPaperId: this.indexMarkList.examPaperId,
            id: item.id,
            markObjective: JSON.stringify(markObjective),
            sheetUuid: this.indexMarkList.sheetUuid,
            onlySave: 1,
          };
          this.setSheetMark(data);
        }
        if (markSubjectiveType) {
          let data = {
            examPaperId: this.indexMarkList.examPaperId,
            id: item.id,
            markSubjective: JSON.stringify(markSubjective),
            sheetUuid: this.indexMarkList.sheetUuid,
            onlySave: 1,
          };
          this.setSheetMark(data);
        }
      });
    },
    // 修改分数后提交  限于 提交非当前页JSON文件
    setSheetMark(data) {
      saveExampapersheetmark(data).then(() => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        if (data.id == this.indexMarkList.id) {
          Object.assign(this.indexMarkList, data);
        }
        this.sheetMarkList.map((item) => {
          if (data.id == item.id) {
            Object.assign(item, data);
          }
        });
        this.setAllLineData();
      });
    },
    // 提交修改试题的信息
    editQuestion() {
      this.btnLoading = true;
      this.questionList = new Array()
        .concat(this.objQuestionList)
        .concat(this.subQuestionList);
      this.questionList.map((index) => {
        if (index.isOption == 1) {
          let groupList = this.questionList.filter(
            (i) => index.examQuestionGroup == i.examQuestionGroup
          );
          groupList.map((item, i) => {
            if (
              index.examQuestionSheetValue == item.examQuestionSheetValue &&
              i > 0
            ) {
              index.score = Number(groupList[0].score);
            }
          });
        }
        if (!index.answerArea || index.selectType == 0) {
          return;
        }
        let answerArea = JSON.parse(index.answerArea);
        let questionNumList = answerArea.questionNumList;
        if (!questionNumList) {
          return;
        }
        questionNumList.map((item) => {
          this.questionList.map((i) => {
            if (item.value == i.examQuestionSheetValue) {
              item.examQuestionNumber = i.examQuestionNumber;
            }
          });
        });
        answerArea.questionNumList = questionNumList;
        index.answerArea = JSON.stringify(answerArea);
      });

      exampapersheetdetailUpdateV2list(
        this.paperList.filter(
          (item) => item.subjectId == this.indexSubjectId
        )[0].sheetUuid,
        this.questionList
      )
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "保存成功",
          });
          this.setJson();
          this.exampapersheetdetailV2page();
          this.btnLoading = false;
          this.scopeNumTips = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    getQuestionBlank() {
      let arr = [];
      this.questionList.map((item) => {
        let str = item.examQuestionNumber.toString();
        if (str.indexOf("  ") != -1) {
          arr.push(str);
        } else {
          let newStr = str.trim();
          if (newStr != str) {
            arr.push(str);
          }
        }
      });
      // console.log(arr);
      if (arr.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "数据异常",
          message: h("p", null, [
            h("span", null, "试题"),
            h("span", { style: "color: #FE5F59" }, arr.join(",")),
            h("span", null, "题号包含首尾空格或连续空格，请修改后提交！ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
        return true;
      } else {
        return false;
      }
      // console.log();
    },
    // 保存  先进行基本的判断
    save() {
      this.questionEditList = [];
      let scoreList = [];
      let questionList = [];
      if (this.questionList.length == 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `当前暂无答题卡，请设置后保存`,
        });
        return;
      }
      if (this.getQuestionBlank()) return;
      this.questionList.map((item) => {
        if (
          Number(item.score) < Number(item.scoreMiss) &&
          item.selectType > 0
        ) {
          scoreList.push(item.examQuestionNumber);
        }
        this.questionList.forEach((index) => {
          if (
            item.examQuestionNumber == index.examQuestionNumber &&
            item.examQuestionSheetValue != index.examQuestionSheetValue
          ) {
            questionList.push(index.examQuestionNumber.toString());
          }
        });
      });
      if (scoreList.length > 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `第${scoreList.join(",")}题，漏选大于得分!`,
        });
        return;
      }
      questionList = [...new Set(questionList)];
      // console.log(scoreList);
      if (questionList.length > 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `第${questionList.join(",")}题，题号重复!`,
        });
        return;
      }
      let quesitonGroupArrStr = "";
      let score = 0;
      //获取修改分数的列表
      this.questionList.map((index) => {
        let tempScore = Number(index.score);
        if (index.isOption == 1) {
          if (quesitonGroupArrStr.indexOf("," + index.examQuestionGroup) > -1) {
            tempScore = 0;
          }
          quesitonGroupArrStr += "," + index.examQuestionGroup;
        }
        score = Number(score) + tempScore;
        this.oldQuestionList.map((item) => {
          if (index.id == item.id && item.score != index.score) {
            let data = Object.assign({}, index);
            data.newScore = item.score;
            this.questionEditList.push(data);
          }
        });
      });
      this.scoreSum = score;
      if (this.questionEditList.length > 0) {
        // this.btnLoading = true;
        this.scopeTips = true;
      } else {
        // this.btnLoading = true;
        this.getQuesstionRole();
      }
    },
    // 获取总分是否符合规范
    getQuesstionRole() {
      let scoreList = [];
      let questionList = [];
      let Array = [];
      this.questionList.map((item) => {
        if (
          Number(item.score) < Number(item.scoreMiss) &&
          item.selectType > 0
        ) {
          scoreList.push(item.examQuestionNumber);
        }
        let examQuestionNumber = item.examQuestionNumber.toString();
        if (
          examQuestionNumber.indexOf(",") != -1 ||
          examQuestionNumber.indexOf(";") != -1 ||
          examQuestionNumber.indexOf("，") != -1 ||
          examQuestionNumber.indexOf("；") != -1
        ) {
          Array.push(item.examQuestionNumber);
        }
        this.questionList.forEach((index) => {
          if (
            item.examQuestionNumber == index.examQuestionNumber &&
            item.examQuestionSheetValue != index.examQuestionSheetValue
          ) {
            questionList.push(index.examQuestionNumber.toString());
          }
        });
      });
      if (scoreList.length > 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `第${scoreList.join(",")}题，漏选大于得分!`,
        });
        return;
      }
      questionList = [...new Set(questionList)];
      // console.log(scoreList);
      if (questionList.length > 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `第${questionList.join(",")}题，题号重复!`,
        });
        return;
      }
      if (Array.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "题号异常提示",
          message: h("p", null, [
            h("span", { style: "color: #FE5F59" }, Array.join(" ") + "题"),
            h("span", null, "题号存在"),
            h("span", { style: "color: #FE5F59" }, "；，, ;"),
            h("span", null, "等符号，请修改后重试 "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
        return;
      }
      this.scopeTips = false;
      if (
        this.scoreSum != 150 &&
        this.scoreSum != 90 &&
        this.scoreSum != 100 &&
        this.scoreSum != 300 &&
        this.scoreSum != 110
      ) {
        // this.editQuestion();
        this.scopeNumTips = true;
      } else {
        this.editQuestion();
      }
    },
    getChangeLists() {
      if (this.getQuestionBlank()) return;
      // 主观题
      let arr = JSON.parse(JSON.stringify(this.subQuestionList));
      // 过滤出选做题
      arr = arr.filter((item) => {
        let returnItem = "";
        if (item.isOption == 0) {
          returnItem = item;
        } else {
          let groupList = this.questionList.filter(
            (i) => item.examQuestionGroup == i.examQuestionGroup
          );
          // console.log(groupList.map((item) => item.examQuestionNumber));
          if (
            item.examQuestionSheetValue == groupList[0].examQuestionSheetValue
          ) {
            returnItem = groupList[0];
          }
        }
        return returnItem;
      });
      let oldArr = [...arr];

      // 将删除的选做题添加回去
      let addKey = 0;
      // console.log(JSON.parse(JSON.stringify(arr)));
      // 用来判断之前有几组选做题提添加了几次
      oldArr.forEach((item, index) => {
        let groupList = this.questionList.filter(
          (i) => item.examQuestionGroup == i.examQuestionGroup
        );
        if (item.examQuestionGroup == groupList[0].examQuestionGroup) {
          if (item.isOption) {
            addKey++;
            // console.log(item.examQuestionGroup);
          }
          groupList.map((itemG, indexG) => {
            if (
              itemG.examQuestionSheetValue != item.examQuestionSheetValue &&
              indexG
            ) {
              arr.splice(index + indexG + addKey - 1, 0, itemG);
            }
          });
        }
      });
      // console.log(JSON.parse(JSON.stringify(arr)));
      // 重新排序
      arr.map((item, index) => {
        item.examQuestionSheetOrder = index + 1 + this.objQuestionList.length;
      });

      this.subQuestionList = arr;
      // 拖拽完成后返回被打乱后的顺序 objQuestionList subQuestionList
      this.objQuestionList.map((item, index) => {
        item.examQuestionSheetOrder = index + 1;
      });
      this.questionList = new Array()
        .concat(this.objQuestionList)
        .concat(this.subQuestionList);
    },
    showScore(item) {
      let returnType = true;
      // let arrNum = 0;
      if (item.isOption) {
        let groupList = this.questionList.filter(
          (i) => item.examQuestionGroup == i.examQuestionGroup
        );
        groupList.map((index, i) => {
          if (
            index.examQuestionSheetValue == item.examQuestionSheetValue &&
            i
          ) {
            returnType = false;
          }
        });
      }
      return returnType;
    },
    subPaperUuid() {
      let data = {
        sheetUuid: this.sheetUuid,
        examPaperId: this.paperList.filter(
          (item) => item.subjectId == this.indexSubjectId
        )[0].value,
        ifCover: this.ifCover,
      };
      this.btnLoading = true;
      exampapersheetDuplicate(data)
        .then(() => {
          this.$message({
            message: "使用成功！",
            type: "success",
          });
          this.getObj();
          this.reuseDingVisible = false;
          setTimeout(() => {
            this.btnLoading = false;
          }, 500);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setPaperUuid() {
      if (!this.sheetUuid) {
        this.$message({
          showClose: true,
          message: "请选择答题卡模板",
          type: "warning",
        });
        return;
      }
      if (this.ifCover) {
        this.$confirm(
          "勾选同步试题，会覆盖“试题管理”中已有试题，如果“试题管理”中已经上传/裁切题目，请谨慎操作。是否确定同步试题?",
          "温馨提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.subPaperUuid();
          })
          .catch(() => {});
      } else {
        this.subPaperUuid();
      }

      // console.log(this.sheetUuid);
    },
    remoteMethod(query) {
      if (query !== "") {
        this.searchSheet.name = query;
        this.exampapersheetPage();
      } else {
        this.sheetIdList = [];
      }
    },
    showReuse() {
      this.reuseDingVisible = true;
      this.searchSheet.subjectId = this.indexSubjectId;
      this.searchSheet.levelId = this.examInformation.level.toString();
      // this.searchSheet.year = this.examInformation.year;
      // this.examInformation;
      this.sheetUuid = "";
      this.exampapersheetPage();
    },
    checkPage() {
      this.sheetUuid = "";
      this.exampapersheetPage();
    },
    exampapersheetPage() {
      this.sheetIdList = [];
      this.loading = true;
      let data = this.searchSheet;
      let data1 = {
        size: 50,
        current: 1,
      };
      data.isValid = 1;
      exampapersheetPage(data, data1)
        .then((res) => {
          this.sheetIdList = res.data.data.records;
          let sheetId = this.paperList.filter(
            (i) => i.subjectId == this.indexSubjectId
          )[0].sheetUuid;
          this.sheetIdList = this.sheetIdList.filter(
            (item) => item.uuid != sheetId
          );
          // console.log()
          // console.log(res);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 设置不是当前项的线条
    setAllLineData() {
      this.allSheetLine = [];
      // 将所有的线条合并 allSheetLine
      let data = this.indexMarkList;
      this.markTypeList.forEach((item) => {
        data[item.key] = data[item.key] || "[]";
        this.allSheetLine = this.allSheetLine.concat(
          JSON.parse(data[item.key])
        );
      });
      // this.allSheetLine = this.allSheetLine.filter(
      //   (item) => item.type != this.GET_LABELLED_TYPE
      // );
      this.setAllLine();
    },
    // 加载答题卡
    async onloadImg() {
      // this.indexSheetMark = this.indexMarkList;
      this.indexImgUrl = this.indexMarkList.urlAdjust;
      await this.setImg();
      // console.log("111111111111");
      // 获取cavas需要渲染的当前列表
      this.sheetLineList = this.sheetMarkList;

      // 设置cavas参数
      this.canvas = this.$refs.lineCanvas;
      this.ctx = this.canvas.getContext("2d");
      this.canvas.width = this.viewWidth;
      this.canvas.height = this.oldHeight / (this.oldWidth / this.viewWidth);
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      if (
        this.indexSubject.sheetType == 1 ||
        (this.indexSubject.sheetType != 1 && this.$route.query.password)
      ) {
        this.setAllLineData();
      }
    },
    // 新建一个专门处理选做题问题
    setLineNameChoose(data, item) {
      data = JSON.parse(JSON.stringify(data));
      let width = 0;
      let height = 24;
      let str = "";
      let array = [];
      item.questionNumList.forEach((item) => {
        array.push(item.examQuestionNumber);
      });

      str = `选做题${array.join(",")}识别区域`;
      width = 15 * str.length;
      // console.log(str);
      // 如果小于20 框就显示在下方
      if (data.top < 50) {
        data.top = (data.height + data.top) / this.proportion;
      } else {
        data.top = data.top / this.proportion - 23;
      }
      //设置矩形

      this.ctx.fillStyle = "rgba(255, 0, 0, 0.6)";

      this.ctx.fillRect(
        data.left / this.proportion - 1,
        data.top - 1,
        width,
        height
      );

      // 文字
      this.ctx.fillStyle = "#ffffff";
      this.ctx.font = "14px Arial";
      this.ctx.fillText(str, data.left / this.proportion + 4, data.top + 16);
    },
    // 根据传入的坐标 计算框内容 大小
    setLineName(data, item, type, index) {
      let width = 0;
      let height = 24;
      let str = "";
      // 定位点
      if (item.type == 1) {
        str = "定位点";
        if (item.subType == 3) {
          str = "定位线";
        }
        width = 55;
      }
      // 标题
      if (item.type == 2) {
        str = "标题";
        width = 45;
      }
      // 考号
      if (item.type == 3) {
        width = 55;
        if (item.subType == 1) {
          str = "填涂式";
        }
        if (item.subType == 2) {
          str = "条形码";
        }
        if (item.subType == 3) {
          str = "输入式";
        }
      }
      // 主观题
      if (item.type == 5) {
        let array = [];
        item.questionNumList.forEach((item) => {
          array.push(item.examQuestionNumber);
        });
        str = `${array.join(",")}(${item.questionNumList[0].score}分)`;
        width = 15 * str.length;
        // console.log(item);
      }
      // 考号
      if (item.type == 6) {
        // width = 55;
        if (item.subType == 1) {
          str = "缺考";
        }
        if (item.subType == 2) {
          str = "学科标记";
        }
        if (item.subType == 3) {
          str = "A,B卷标记";
        }
        width = 22 + 10 * str.length;
      }
      // 如果小于20 框就显示在下方
      if (data.top < 50) {
        data.top = (data.height + data.top) / this.proportion;
      } else {
        data.top = data.top / this.proportion - 23;
      }
      //设置矩形
      if (type) {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.6)";
      } else {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.6)";
      }
      if (index) {
        str = str + `续${index}`;
        width = width + 30;
      }
      this.ctx.fillRect(
        data.left / this.proportion - 1,
        data.top - 1,
        width,
        height
      );

      // 文字
      this.ctx.fillStyle = "#ffffff";
      this.ctx.font = "14px Arial";
      this.ctx.fillText(str, data.left / this.proportion + 4, data.top + 16);
    },
    callFunction(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          this.oldWidth = img.width;
          this.oldHeight = img.height;
          resolve(true);
        };
      });
    },
    async setImg() {
      // 获取canvas对象
      const imgCanvas = document.getElementById("imgCanvas");
      // 创建img对象
      let img = document.createElement("img");
      // console.log(this.$refs.markSheet);
      // 设置src值
      img.src = encodeURI(this.indexMarkList.urlAdjust);

      const viewWidth = this.$refs.markSheet.clientWidth;

      this.oldLeft = (this.$refs.markSheet.clientWidth * 0.1) / 2;
      this.viewWidth = viewWidth;
      const _this = this;

      //设置canvas属性 oldWidth oldHeight viewWidth
      await this.callFunction(img);

      // 当前比例
      _this.proportion = _this.oldWidth / _this.viewWidth;
      //重新定义图片宽高
      img.height = (img.height * viewWidth) / img.width;
      img.width = viewWidth;

      imgCanvas.width = viewWidth;
      _this.viewHeight = img.height;
      imgCanvas.height = img.height;
      imgCanvas.style.backgroundSize = `${img.width}px ${img.height}px`;
      imgCanvas.style.backgroundImage = 'url("' + img.src + '")';

      // 设置装cavans的盒子大小
      _this.$refs.markSheet.style.height = img.height + "px";
      _this.$refs.markSheet.style.width = viewWidth + "px";
      // console.log("sss");
    },
    // 如果是小小框  根据位置生成相对位置
    setExamIdPotint(item) {
      // if (item.type == 6) {
      //   console.log(item);
      // }

      // item 当前项数据 type 为1就是当前线条  颜色深点 其他就是非当前项线条 options 选项个数
      // console.log(item.subType);
      // 如果不是填涂式考号
      if (item.type == 3 && item.subType != 1) {
        return;
      }
      // points 内部框的内容（最左上角到最右下角）
      // 最小框的右边距 有两种情况  横式和竖式答题卡存在区别
      // 右边距 上边距  大盒子宽度  小盒子宽度  小盒子高度  大盒子高度
      let sizeRight, sizeBottom, sizeWidth, sizeHeight, bigHeight, bigWidth;
      // 如果已经是计算过的 就不需要重新计算了
      if (item.calculate && item.calculate.sizeWidth) {
        // console.log("进了");
        sizeRight = item.calculate.sizeRight;
        sizeBottom = item.calculate.sizeBottom;
        bigWidth = item.calculate.bigWidth;
        sizeWidth = item.calculate.sizeWidth;
        sizeHeight = item.calculate.sizeHeight;
        bigHeight = item.calculate.bigHeight;
      } else {
        // childPoints 是最小框的位置以及大小
        // 先计算出 最小框的宽度和高度
        // 左上角到框左边的距离
        item.leftTopWidthSize = item.points[0].x;
        // 左上角到框顶部的距离
        item.leftTopHeightSize = item.points[0].y;
        sizeWidth = item.childPoints[1].x - item.childPoints[0].x;
        bigWidth = item.points[1].x - item.points[0].x;
        sizeHeight = item.childPoints[2].y - item.childPoints[0].y;
        bigHeight = item.points[2].y - item.points[0].y;
        item.calculate = {};
        // 如果是考号 type为3的处理方式和客观题的处理方式不一样
        if (
          (item.type != 3 && item.optionsArrangementMode == 1) ||
          (item.optionsArrangementMode == 2 && item.type == 3)
        ) {
          // console.log("进入的第一步");
          sizeRight = (
            (bigWidth - sizeWidth * item.optionsLength) /
            (item.optionsLength - 1)
          ).toFixed(2);
          sizeBottom = (
            (bigHeight - sizeHeight * item.questionNum) /
            (item.questionNum - 1)
          ).toFixed(2);
        } else {
          // console.log("进入的第er步");
          sizeRight = (
            (bigWidth - sizeWidth * item.questionNum) /
            (item.questionNum - 1)
          ).toFixed(2);
          sizeBottom = (
            (bigHeight - sizeHeight * item.optionsLength) /
            (item.optionsLength - 1)
          ).toFixed(2);
        }
        // 如果是选做的情况 计算方式
        if (item.type == 5) {
          if (item.optionsArrangementMode == 1) {
            sizeRight = (
              (bigWidth - sizeWidth * item.questionNum) /
              (item.questionNum - 1)
            ).toFixed(2);
            sizeBottom = 0;
          } else {
            sizeRight = 0;
            sizeBottom = (
              (bigHeight - sizeHeight * item.questionNum) /
              (item.questionNum - 1)
            ).toFixed(2);
          }
        }
      }

      // sizeRight = sizeRight == "Infinity" ? 0 : sizeRight;
      // console.log(bigWidth);
      // console.log(sizeWidth);
      // console.log(sizeRight);
      // console.log(sizeHeight);
      // console.log(item.questionNum);
      // 处理下没有下边距和右边距的情况
      if (
        sizeRight == "Infinity" ||
        sizeRight == "NaN" ||
        Number(sizeRight) < 0
      ) {
        sizeRight = 0;
      }
      if (
        sizeBottom == "Infinity" ||
        sizeBottom == "NaN" ||
        Number(sizeBottom) < 0
      ) {
        sizeBottom = 0;
      }
      item.calculate.sizeWidth = sizeWidth;
      item.calculate.bigWidth = bigWidth;
      item.calculate.sizeHeight = sizeHeight;
      item.calculate.bigHeight = bigHeight;
      item.calculate.sizeRight = sizeRight;
      item.calculate.sizeBottom = sizeBottom;
      // console.log(item.calculate);
      // 循环添加出一个数组

      for (let i = 0; i < item.questionNum; i++) {
        // 如果是客观题  需要显示每个题的小题号
        if (item.type == 4) {
          // console.log(type);
          // 正方形
          // if (type) {
          //   this.ctx.fillStyle = "rgba(255, 0, 0, 0.4)";
          // } else {
          this.ctx.fillStyle = "rgba(255, 0, 0, 1)";
          // }
          this.ctx.font = "12px Arial";
          let top =
            sizeHeight * i +
            sizeBottom * i +
            item.points[0].y +
            item.source.top;
          let right =
            sizeWidth * i + sizeRight * i + item.points[0].x + item.source.left;
          if (item.optionsArrangementMode == 1) {
            right = item.source.left;
            right =
              right / this.proportion -
              ((item.questionNumList[i].examQuestionNumber.toString().length -
                1) *
                12) /
                this.proportion -
              16;
            top = (top + 16) / this.proportion;
          } else {
            top = item.source.top;
            right = (right + 12) / this.proportion;
            top = (top + 12) / this.proportion - 20;
          }
          this.ctx.fillText(
            item.questionNumList[i].examQuestionNumber,
            right,
            top
          );
        }
        for (let j = 0; j < item.optionsLength; j++) {
          let data = {
            left:
              sizeWidth * j +
              sizeRight * j +
              item.points[0].x +
              item.source.left,
            top:
              sizeHeight * i +
              sizeBottom * i +
              item.points[0].y +
              item.source.top,
            width: sizeWidth,
            height: sizeHeight,
          };
          // if (type) {
          //   this.ctx.strokeStyle = "rgba(255, 0, 0, 0.4)";
          // }
          // console.log(item);
          // console.log(item.optionsArrangementMode);
          // 调换横向  竖向
          if (
            (item.type == 5 && item.optionsArrangementMode == 1) ||
            (item.type == 4 && item.optionsArrangementMode == 2) ||
            (item.type == 6 &&
              item.optionsArrangementMode == 2 &&
              item.subType == 3)
          ) {
            data = {
              left:
                sizeWidth * i +
                sizeRight * i +
                item.points[0].x +
                item.source.left,
              top:
                sizeHeight * j +
                sizeBottom * j +
                item.points[0].y +
                item.source.top,
              width: sizeWidth,
              height: sizeHeight,
            };
          }
          // // 如果是缺考标记 特殊处理下
          if (item.type == 6 && item.subType == 1) {
            data = {
              left: sizeWidth * i + item.points[0].x + item.source.left,
              top: sizeHeight * j + item.points[0].y + item.source.top,
              width: sizeWidth,
              height: sizeHeight,
            };
          }

          this.setStrokeRect(data);
        }
      }
    },
    // 多点类型点绘制（页面显示的浅色点）
    setAllLine() {
      this.allSheetLine.forEach((item) => {
        if (!item.source) {
          return;
        }

        let data = {
          left: item.source.left,
          top: item.source.top,
          width: item.source.width,
          height: item.source.height,
        };
        // console.log(item);
        //设置线条颜色，必须放在绘制之前
        this.ctx.strokeStyle = "rgba(255, 0, 0, 1)";
        this.ctx.lineWidth = 1;
        this.setStrokeRect(data);
        // 设置线条名称
        this.setLineName(data, item);
        if ((item.type == 1 && item.subType != 3) || item.type == 2) {
          this.setCountPoint(item);
        }
        if (item.type == 3 || item.type == 4) {
          this.setExamIdPotint(item, 1);
        }
        // console.log(this.GET_LABELLED_TYPE);
        // console.log(this.subjectiveType);
        if (item.type == 5 && item.subjectiveType == 1) {
          if (item.chooseQuestionLocation) {
            let data1 = {
              left: item.chooseQuestionLocation.source.left,
              top: item.chooseQuestionLocation.source.top,
              width: item.chooseQuestionLocation.source.width,
              height: item.chooseQuestionLocation.source.height,
            };
            this.setStrokeRect(data1);
            item.chooseQuestionLocation.questionNum = item.questionNum;
            item.chooseQuestionLocation.optionsArrangementMode =
              item.optionsArrangementMode;
            this.setExamIdPotint(
              item.chooseQuestionLocation,
              0,
              item.questionNum
            );
            this.setLineNameChoose(item.chooseQuestionLocation.source, item);
          }
        }
        if (item.type == 5 && item.subjectiveType == 2) {
          // console.log(item.chooseQuestionLocationArr);
          item.chooseQuestionLocationArr.map((index, key) => {
            let data1 = {
              left: index.source.left,
              top: index.source.top,
              width: index.source.width,
              height: index.source.height,
            };
            this.setStrokeRect(data1);
            // 设置线条名称
            this.setLineTsNameChoose(data1, item, key, 1);
            if (!index.questionNum) {
              index.questionNum = 1;
            }
            this.setExamIdPotint(index, 0);
          });
        }
        if (item.type == 6) {
          if (!item.source) {
            return;
          }
          if (item.subType == 2) {
            this.setCountPoint(item, 1);
          } else {
            this.setExamIdPotint(item, 0, 1);
          }
        }
        // 如果存在追加区域
        if (item.extendList) {
          item.extendList.forEach((index, i) => {
            if (index.priority != item.priority) {
              return;
            }
            let data1 = {
              left: index.source.left,
              top: index.source.top,
              width: index.source.width,
              height: index.source.height,
            };
            this.setStrokeRect(data1);
            this.setLineName(data1, item, 0, i + 1);
          });
        }
      });
      this.sheetMarkList.forEach((item) => {
        if (item.priority != this.indexMarkList.priority) {
          if (!item.markSubjective) {
            return;
          }
          let subArr = JSON.parse(item.markSubjective);
          subArr.forEach((index) => {
            if (index.extendList) {
              index.extendList.forEach((i, key) => {
                if (i.priority == this.indexMarkList.priority) {
                  let data1 = {
                    left: i.source.left,
                    top: i.source.top,
                    width: i.source.width,
                    height: i.source.height,
                  };
                  this.setStrokeRect(data1);
                  this.setLineName(data1, index, 0, key + 1);
                }
              });
            }
          });
        }
      });
    },
    // 特殊选做题name处理
    setLineTsNameChoose(data, item, key, type) {
      data = JSON.parse(JSON.stringify(data));
      let width = 0;
      let height = 24;
      let str = "";
      let array = [];
      item.questionNumList.forEach((item) => {
        array.push(item.examQuestionNumber);
      });

      str = `${item.questionNumList[key].examQuestionNumber}`;
      width = 15 * str.length;
      // 如果小于20 框就显示在下方
      if (data.top < 50) {
        data.top = (data.height + data.top) / this.proportion;
      } else {
        data.top = data.top / this.proportion - 23;
      }
      //设置矩形
      if (type) {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.6)";
      } else {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.4)";
      }

      this.ctx.fillRect(
        data.left / this.proportion - 1,
        data.top - 1,
        width,
        height
      );

      // 文字
      this.ctx.fillStyle = "#ffffff";
      this.ctx.font = "14px Arial";
      this.ctx.fillText(str, data.left / this.proportion + 4, data.top + 16);
    },
    // 根据传入的点位绘制cavas设置点位
    setStrokeRect(postion) {
      this.ctx.strokeRect(
        postion.left / this.proportion,
        postion.top / this.proportion,
        postion.width / this.proportion,
        postion.height / this.proportion
      );
    },
    // 绘制大框中的小框  针对定位点
    setCountPoint(item, type) {
      let data = {};
      data.width = item.points[1].x - item.points[0].x;
      data.height = item.points[3].y - item.points[0].y;
      data.top = item.source.top + item.points[0].y;
      data.left = item.source.left + item.points[0].x;
      this.ctx.lineWidth = 1;
      if (type) {
        this.ctx.strokeStyle = "#F45454";
      } else {
        this.ctx.strokeStyle = "rgba(255, 0, 0, 1)";
      }

      this.setStrokeRect(data);
    },
    // 答题卡上传 start
    showExport() {
      this.fileList = [];
      this.batchAmendingVisible = true;
    },
    handleImportFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    batchModifyOnError(err, file, fileList) {
      this.loading = false;
      this.handleImportFileRemove(fileList);
    },
    batchModifyOnProgress() {
      this.batchAmendingVisible = false;
      this.loading = true;
    },
    handleImportBefore(file) {
      if (!file.name.endsWith(".json")) {
        this.$message.error("只能上传系统导出的 json 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      const _vm = this;
      _vm.wordData.examPaperId = _vm.indexSubject.value;
      return true;
    },
    handleImportOnProgress() {
      this.batchAmendingVisible = false;
    },
    batchModifyOnSuccess(response) {
      this.batchAmendingVisible = false;
      if (response.code === 0) {
        this.$message({
          showClose: true,
          type: "success",
          message: "导入成功",
          duration: 2000,
        });
        this.getObj();
      } else {
        let responseMsg = response.msg;

        this.$message({
          showClose: true,
          type: "warning",
          message: responseMsg,
          duration: 20000,
        });
      }
      // this.$message({
      //   message: "导入成功！",
      //   type: "success",
      // });
    },
    // 答题卡上传 end
    exportFile() {
      // 导出答题卡
      // console.log(this.paperList);
      let indexSubject = this.paperList.filter(
        (item) => item.subjectId == this.indexSubjectId
      )[0];
      this.indexSubject = indexSubject;
      let data;
      if (indexSubject.sheetUuid) {
        data = {
          sheetUuids: indexSubject.sheetUuid,
        };
      } else {
        this.$message({
          message: "当前试卷没有答题卡，无法下载",
          type: "warning",
        });
        return;
      }

      // console.log(data);

      this.exportBtnLoading = true;
      exampapersheetExport(data)
        .then((res) => {
          this.exportBtnLoading = false;
          let name =
            this.examInformation.examName +
            "-" +
            indexSubject.label +
            "-" +
            "答题卡";
          fileDownloadByUrl(res.data.data.url, name);
          // console.log();
        })
        .catch(() => {
          this.exportBtnLoading = false;
        });
    },
    cancelSheet() {
      this.$refs.cancelSheet.init(this.indexSubject);
    },
    goSheetT() {
      let data = {
        sheetId: this.paperList.filter(
          (i) => i.subjectId == this.indexSubjectId
        )[0].sheetUuid,
        examId: this.$route.query.examId,
      };
      data.from = this.$route.name;
      data.name = this.examInformation.name;
      this.$router.push({
        name: "ThirdPartyAnswerSheet",
        query: data,
      });
    },
    // 修改答题卡模板
    goSheet() {
      let data = {
        sheetId: this.paperList.filter(
          (i) => i.subjectId == this.indexSubjectId
        )[0].sheetUuid,
        examId: this.$route.query.examId,
      };
      //
      let indexPaper = this.paperList.filter(
        (i) => i.subjectId == this.indexSubjectId
      )[0];
      data.from = this.$route.name;
      if (indexPaper.sheetType == 1) {
        this.$router.push({
          name: "ThirdPartyAnswerSheet",
          query: data,
        });
      } else {
        let data = {
          sheetId: indexPaper.sheetUuid,
        };
        this.$router.push({
          name: "SheetEdit",
          query: data,
        });
      }

      // window.open(routeData.href, "_blank");
    },

    // 新建三方模板
    createThree() {
      if (this.indexSubject.sheetUuid) {
        this.$refs.changeSheet.init(this.indexSubject);
        // this.dialogVisibleTips = true;
      } else {
        this.goLink();
      }
    },
    goLink() {
      let data = {
        examId: this.$route.query.examId,
        examName: this.examInformation.examName,
        paperId: this.paperList.filter(
          (i) => i.subjectId == this.indexSubjectId
        )[0].value,
        subjectId: this.paperList.filter(
          (i) => i.subjectId == this.indexSubjectId
        )[0].subjectId,
        subjectName: this.paperList.filter(
          (i) => i.subjectId == this.indexSubjectId
        )[0].label,
      };
      // let Base64 = require("js-base64").Base64;
      data.from = this.$route.name;
      this.$router.push({
        name: "CreartThirdpartySheet",
        query: data,
      });
      // window.open(routeData.href, "_blank");
    },

    checkSheet(v) {
      this.oldHeight = 0;
      if (this.ctx) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      }
      this.$nextTick(() => {
        this.indexMarkList = v;
        this.onloadImg();
      });
    },
    exampapersheetmarkList(v) {
      this.sheetMarkList = [];
      this.indexMarkList = {};
      if (!v.sheetUuid) {
        return;
      }
      let data = {
        sheetUuid: v.sheetUuid,
      };
      exampapersheetmarkList(data).then((res) => {
        this.$nextTick(() => {
          this.sheetMarkList = res.data.data;
          this.indexMarkList = this.sheetMarkList[0];
          this.$nextTick(() => {
            this.onloadImg();
          });
        });
      });
    },
    checkQuesion() {
      // if (item.isOption) {
      this.questionList.map((index) => {
        this.questionList.map((item) => {
          if (
            item.isOption &&
            index.examQuestionGroup == item.examQuestionGroup
          ) {
            if (index.examQuestionSheetValue > item.examQuestionSheetValue) {
              index.score = item.score;
            }
          }
        });
      });
      // }
      // let oldArr=JSON.parse(JSON.stringify(this.questionList))
      exampapersheetdetailUpdateV2list(
        this.paperList.filter(
          (item) => item.subjectId == this.indexSubjectId
        )[0].value,
        this.questionList
      );
    },
    checkSubject(v) {
      this.indexSubjectId = v.subjectId;
      this.indexPaperId = v.value;
      this.indexSubject = v;
      let query = {
        subjectId: v.subjectId,
        examId: this.$route.query.examId,
      };
      if (this.$route.query.password) {
        query.password = this.$route.query.password;
      }
      this.$router.push({
        query: query,
      });

      this.$nextTick(() => {
        this.$refs.ExamDetailsHeader.init(this.indexSubject.value);
      });
      this.exampapersheetdetailV2page();
      this.exampapersheetmarkList(v);
    },
    getNewView() {
      this.exampapersheetdetailV2page();
      this.exampapersheetmarkList(this.indexSubject);
    },
    exampapersheetdetailV2page() {
      let data = {
        sheetUuid: this.paperList.filter(
          (item) => item.subjectId == this.indexSubjectId
        )[0].sheetUuid,
        // subjectId: this.indexSubjectId,
      };
      this.questionList = [];
      this.subQuestionList = [];
      this.objQuestionList = [];
      if (!data.sheetUuid) {
        return;
      }
      exampapersheetdetailV2page(data).then((res) => {
        this.oldQuestionList = JSON.parse(JSON.stringify(res.data.data));
        this.questionList = res.data.data;
        this.subQuestionList = res.data.data.filter((item) => item.type == 1);
        this.objQuestionList = res.data.data.filter((item) => item.type != 1);
        // console.log(res);
      });
    },
    getObj() {
      if (!this.indexSubjectId) {
        this.indexSubjectId = this.$route.query.subjectId;
      }

      getObj(this.$route.query.examId).then((response) => {
        this.examInformation = response.data.data;
        this.examInformation.examName = response.data.data.name;
        this.paperList = response.data.data.paperList.map((i) => {
          return {
            label: i.subjectName,
            value: i.paperId,
            subjectId: i.subjectId,
            sheetUuid: i.sheetUuid,
            sheetType: i.sheetType,
            markType: i.markType,
            getType: i.getType,
            sheetMarkType: i.sheetMarkType,
            ifCover: i.ifCover,
          };
        });
        if (this.indexSubjectId == 0) {
          this.indexSubjectId = this.paperList[0].subjectId;
        }

        let data = this.paperList.filter(
          (item) => item.subjectId == this.indexSubjectId
        )[0];
        this.indexPaperId = data.value;
        this.indexSubject = data;

        this.exampapersheetmarkList(data);
        this.exampapersheetdetailV2page();
        this.$nextTick(() => {
          this.$refs.ExamDetailsHeader.init(this.indexSubject.value);
        });
      });
    },
  },
};
</script>
<style lang="scss">
.qustion-order-list-sub.qustion-order-list-item {
  > div {
    &:first-child {
      width: 40%;
    }
  }
}
.sub-qustion-order-list.hidden-list {
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0;
  height: 0;
}
.sub-qustion-order-list {
  .question-box-list {
    display: flex;
    .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
    > div {
      margin-bottom: 12px;
      width: 120px;
      &:first-child {
        margin-right: 18px;
      }
    }
  }
}
.qustion-order-list-item {
  display: flex;
  margin-bottom: 12px;
  color: #5b625e;
  cursor: pointer;
  .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
  > div {
    width: 110px;
    flex-shrink: 0;
    margin-right: 18px;
    &:first-child {
      width: 120px;
    }
    &:last-child {
      margin-right: 0;
      width: 50px;
    }
  }
}
</style>
<style lang="scss" scoped>
.ExamTemplate {
  padding: 24px 0 60px;
  .split-question {
    .el-input {
      width: 107px;
    }
    .split-title {
      // margin-bottom: 16px;
      margin: 8px 16px 8px 0;
      display: inline-block;
    }
    .el-radio-group {
      max-height: 200px;
      padding-left: 1px;
      margin: 8px 0;
    }
    .el-radio {
      min-width: 90px;
      margin-right: 0;
      margin: 8px 0;
    }
    .el-checkbox {
      min-width: 90px;
      margin-right: 0;
      margin: 8px 0;
    }
  }
  .self-amending-form {
    // ::v-deep .el-dialog__body {
    //   padding-bottom: 15px;
    // }
  }
  .qustion-order-list-box {
    max-height: 400px;
    padding-right: 12px;
  }
  .btn-box {
    display: flex;
    margin-top: 18px;
    justify-content: center;
  }
  .tips {
    color: #3e4551;
    font-size: 12px;
  }

  .select-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .name-select {
      width: calc(100% - 90px);
    }
    p {
      width: 90px;
      margin-bottom: 0;
      text-align: right;
    }
  }
  .paper-question-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 18px;
    .scan-none-list {
      margin: 60px auto;
      text-align: center;
      p {
        color: #ababab;
      }
    }

    .paper-img-box {
      width: 100%;

      padding: 18px;
      overflow: hidden;
      border: 1px dashed #e9e9e9;

      .sheet-img {
        position: relative;

        margin-top: 40px;
        canvas {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          // top: 40px;
        }
        // img {
        //   width: 100%;
        // }
      }
      .paper-img-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        .btn-sheet-box {
          position: absolute;
          right: 0;
          top: 0;
        }

        .el-button + .el-button {
          right: 80px;
        }
        .sheet-list {
          width: 22px;
          height: 22px;
          border: 1px solid #5b625e;
          color: #5b625e;
          border-radius: 50%;
          text-align: center;
          line-height: 22px;
          margin-right: 24px;
          letter-spacing: 0;
          cursor: pointer;
        }
        .check-on {
          color: $primary-color;
          border: 1px solid $primary-color;
        }
      }
    }
    .peper-question-list-box {
      width: 518px;
      margin-left: 18px;
      flex-shrink: 0;
      background-color: #ffffff;
      border: 1px solid #e9e9e9;
      border-bottom: none;
      .objective-list-box {
        border-bottom: 1px solid #dddddd;
        padding: 18px;
        > p {
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: #0a1119;
          font-weight: bold;
        }
      }
      .peper-question-header {
        padding: 8px 18px;
        background: #f9fafc;
        border-bottom: 1px solid #dddddd;
      }
      .paper-header {
        font-size: 14px;
        color: #0a1119;
        font-weight: bold;
      }
    }
  }
  .paper-list-box {
    margin-top: 24px;
    background-color: #fff;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    border-bottom: 1px solid #f7f7f7;
    .paper-item {
      display: flex;
    }
    .paper-list {
      margin-right: 24px;
      padding-bottom: 5px;
      border-bottom: 2px solid #fff;
      cursor: pointer;
      white-space: nowrap;
    }
    .on {
      // border-bottom: 2px solid $primary-color;
      color: $primary-color;
    }
  }
  .upload-tips {
    margin-bottom: 12px;
  }
  .err {
    margin-bottom: 12px;
    color: #fe5f59;
  }
  .upload-file {
    // margin-bottom: 24px;
    ::v-deep .el-upload {
      display: block;

      .el-upload-dragger {
        width: 100%;
      }
    }
    ::v-deep .el-upload--text {
      // line-height: 180px;
    }
  }
}
</style>
