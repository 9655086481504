<template>
  <div class="detailsHeader">
    <div class="goback">
      <img
        v-if="!hiddenBack"
        class="backImg"
        src="@/assets/newExam/back.svg"
        alt=""
        @click="goBack()"
      />
      <span @click="goBack()"> 您的位置：{{ examInformation.name }} </span>

      <template v-if="menuList.length > 0">
        <span> / </span>
        <el-dropdown v-if="roleId != 5">
          <span class="el-dropdown-link">
            {{ indexName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>

          <el-dropdown-menu v-if="roleId != 5" slot="dropdown">
            <template v-for="(v, i) in menuList">
              <el-dropdown-item
                v-if="v.name != indexName"
                :key="i"
                @click.native.stop="check(v)"
              >
                {{ v.name }}
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else class="el-dropdown-link">
          {{ indexName }}
        </span>
      </template>

      <template v-if="$parent.indexPaperId">
        <span> / </span>
        <el-dropdown v-if="roleId != 5 && !$route.meta.noSubItem">
          <span class="el-dropdown-link">
            {{ $parent.indexPaperId | setName($parent.paperList) }}
            <i v-if="roleId != 5" class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu v-if="roleId != 5" slot="dropdown">
            <el-dropdown-item
              v-for="(v, i) in $parent.paperList"
              :key="i"
              @click.native.stop="$parent.checkSubject(v)"
            >
              {{ v.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else class="el-dropdown-link">
          {{ $parent.indexPaperId | setName($parent.paperList) }}
        </span>
      </template>
    </div>
    <div v-if="showPopver" class="title-right">
      <span v-if="examInformation.ruleId > 0" class="title-tips title-type-new">
        新高考
      </span>
      <span v-else class="title-tips">普通考试</span>

      <el-popover
        placement="bottom"
        width="360"
        trigger="hover"
        style="margin-left: 18px"
        popper-class="tips-details"
        :open-delay="300"
        :close-delay="0"
      >
        <div class="details-tips-box">
          <span v-if="examInformation.ruleId > 0" class="details-tips">
            新高考
          </span>
          <div class="name-box">
            <span v-if="examInformation.status == 3" class="status">
              已统计
            </span>
            <span v-if="examInformation.status == 2" class="status status-succ">
              已结束
            </span>
            <span v-if="examInformation.status == 1" class="status status-ing">
              进行中
            </span>
            <p class="title">{{ examInformation.name }}</p>
          </div>
          <div style="margin-bottom: 18px">
            <span class="title-tips">
              {{ examInformation.level | setName(levelOptions) }}
            </span>
            <span class="title-tips">{{ examInformation.year }}级</span>
            <span class="title-tips">
              {{ examInformation.type | setName(examTypeOptions) }}
            </span>
            <span class="title-tips">
              {{ examInformation.examDate }}
            </span>
          </div>
          <div class="security-box">
            <img
              v-if="examInformation.securityLevel == 1"
              src="@/assets/newExam/icon-safety.svg"
              alt=""
            />
            <img
              v-if="examInformation.securityLevel == 2"
              src="@/assets/newExam/icon-safety-err.svg"
              alt=""
            />
            风险等级：
            <template v-if="examInformation.securityLevel == 1">低</template>
            <template v-if="examInformation.securityLevel == 2"
              >高，禁止数据发布</template
            >
          </div>
        </div>

        <div slot="reference" class="title-tips" style="cursor: pointer">
          {{ examInformation.level | setName(levelOptions) }}
          <span class="shu">|</span>
          {{ examInformation.year }}
          <span class="shu">|</span>
          {{ examInformation.type | setName(examTypeOptions) }}
          <span class="shu">|</span>
          <span
            class="fx"
            :class="{ error: examInformation.securityLevel == 2 }"
          >
            {{
              examInformation.securityLevel | setName(examSecurityLevelOptions)
            }}
          </span>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
// import {}
import {
  examTypeOptions,
  examSecurityLevelOptions,
  levelOptions,
  // gradeHighOptions,
  // gradeMiddleOptions,
  // subjectOptions,
  // examReportAutoSendOptions,
} from "@/core/util/constdata";
export default {
  name: "DetailsHeader",
  components: {},
  props: {
    hiddenBack: {
      type: Boolean,
      default: false,
    },
    showPopver: {
      type: Boolean,
      default: true,
    },
    routerName: {
      type: String,
      default: function () {
        return " / " + this.$route.meta.title;
      },
    },
    // 如果需要跳转到指定位置
    routerUrl: {
      type: String,
      default: "",
    },
    // 指定路由地址传参
    query: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menuList: [],
      indexName: "",
      roleId: "",
      levelOptions: levelOptions(),
      examSecurityLevelOptions: examSecurityLevelOptions,
      examTypeOptions: examTypeOptions,
      examInformation: {},
    };
  },
  watch: {
    "$parent.examInformation": {
      deep: true,
      handler() {
        if (this.$parent && this.$parent.examInformation) {
          // console.log(this.examInformation);
          this.examInformation = JSON.parse(
            JSON.stringify(this.$parent.examInformation)
          );
        }
      },
    },
  },
  created() {
    if (this.$parent && this.$parent.examInformation) {
      this.examInformation = JSON.parse(
        JSON.stringify(this.$parent.examInformation)
      );
    }
  },
  methods: {
    check(item) {
      // console.log(item);
      let { url } = item;
      let query = {};
      let { examId, level, examType } = this.$parent.examInformation;
      let { paperId, subjectId, getType, markType } = this.indexPaper;
      if (url == "ExamTemplate") {
        query = {
          examId: examId,
          subjectId: subjectId,
        };
      } else if (url == "ExamPaperAnswer") {
        query = {
          examId: examId,
          paperId: paperId,
        };
      } else if (url == "scanSheet") {
        query = {
          examId: examId,
          examPaperId: paperId,
          level: level,
          examType: examType,
        };
      } else if (url == "ExamCorrectTask") {
        let data = {
          paperId: paperId,
          examId: examId,
          examType: examType,
        };
        if (markType == 2) {
          url = "ExamClassReadCorrectTask";
        }
        // console.log(data);
        query = data;
      } else {
        query = {
          examPaperId: paperId,
          examId: examId,
          subjectId: subjectId,
          examType: examType,
          getType: getType,
          markType: markType,
          routerName: "examlist",
          // subjectName: item.subjectName,
        };
      }
      if (examType == 1) {
        url = "union" + url;
        query.routerName = "/exam/unionExam";
      }
      this.$router.push({
        name: url,
        query: query,
      });
    },
    init(paperId) {
      let data = [];
      let ExamTemplate = { name: "模版设置", url: "ExamTemplate" };
      let ExamPaperAnswer = { name: "答案设置", url: "ExamPaperAnswer" };
      let scanSheet = { name: "扫描管理", url: "scanSheet" };
      let ExamCorrectTask = { name: "阅卷分配", url: "ExamCorrectTask" };
      let MarkingProgress = { name: "阅卷进度", url: "MarkingProgress" };
      this.indexPaper = this.$parent.examInformation.paperList.filter(
        (item) => item.paperId == paperId
      )[0];

      data.push(ExamTemplate);
      if (this.indexPaper.statusSheet) {
        data.push(ExamPaperAnswer);
        data.push(scanSheet);
        if (this.indexPaper.sheetMarkType != 1) {
          data.push(ExamCorrectTask);
          data.push(MarkingProgress);
        }
      }

      const { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.roleId = roleId;
      this.indexName = this.$route.meta.titleSub || this.$route.meta.title;
      this.menuList = data;
    },
    goBack() {
      if (this.routerUrl) {
        this.$router.push({
          name: this.routerUrl,
          query: this.query,
        });
      } else {
        this.$router.go(-1);
      }
    },
    // init(value) {
    //   this.examInformation = JSON.parse(JSON.stringify(value));
    // },
  },
};
</script>
<style lang="scss">
.tips-details {
  padding: 0;
  .details-tips-box {
    padding: 24px;
    position: relative;
    overflow: hidden;
    .security-box {
      display: flex;
      align-items: center;
      line-height: 14px;
      img {
        margin-right: 8px;
      }
    }
    .name-box {
      margin-bottom: 18px;
      align-items: flex-start;
      display: flex;
      span {
        flex-shrink: 0;
      }
      p {
        margin-bottom: 0;
      }
    }
    .title-tips {
      padding: 4px 8px;
      color: #2474ed;
      background: #f3f8ff;
      border-radius: 4px;
      font-size: 12px;
      // display: flex;
    }
    .title-tips + .title-tips {
      margin-left: 8px;
    }
    .details-tips {
      position: absolute;
      right: -43px;
      top: 16px;
      transform: rotate(45deg);
      font-size: 12px;
      background: #fff8f3;
      width: 100px;
      padding: 4px 0 2px 18px;
      color: #ff7a00;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #0a1119;
      padding: 4px 0;
      line-height: 14px;
      // vertical-align: middle;
    }
    .status {
      font-size: 12px;
      background-color: #ffab00;
      color: white;
      border-radius: 3px;
      margin-right: 10px;
      padding: 3px 8px;
    }
    .status-succ {
      background-color: $success-color;
    }

    .status-ing {
      background-color: $primary-color;
    }
  }
}
</style>
<style scoped lang="scss">
.detailsHeader {
  padding: 16px 18px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 14px;
  }

  .title-tips {
    padding: 6px 18px;
    background: #f3f8ff;
    border-radius: 4px;
    color: #2474ed;
    font-size: 14px;
    // display: flex;
    // align-items: center;
    .error {
      color: #ff0000;
      // margin-bottom: 0;
    }

    .shu {
      color: #3e4551;
      opacity: 0.14;
      font-size: 12px;
      margin: 0 3px;
    }
  }
  .title-right {
    display: flex;
    align-items: center;
  }

  .title-type-new {
    background: #fff8f3;
    color: #ff7a00;
  }
  .backImg {
    margin-right: 8px;
    vertical-align: text-bottom;
  }
  .goback {
    cursor: pointer;
  }
}
</style>
