<template>
  <div class="changeSheet">
    <el-dialog
      title="不满足更换答题卡条件，请检查！"
      :visible.sync="dialogVisible"
      width="430px"
      :close-on-click-modal="false"
    >
      <div class="item-sheet-box">
        <div class="item-sheet">
          <template v-if="indexStatus.answerStatus === 0">
            <img src="@/assets/newExam/sheet/icon_check.png" alt="" />
            试卷答案已清空！
          </template>
          <template v-else>
            <img src="@/assets/newExam/sheet/icon_clear.png" alt="" />
            试卷答案尚未清空！
          </template>
          <el-button type="text" @click="linkTo(1)">前往答案设置</el-button>
        </div>
        <div class="item-sheet">
          <template v-if="indexStatus.allocateStatus === 0">
            <img src="@/assets/newExam/sheet/icon_check.png" alt="" />
            <template v-if="indexPaper.sheetMarkType == 1">
              先阅后扫无需处理!
            </template>
            <template v-else> 阅卷教师已清空！</template>
          </template>
          <template v-else>
            <img src="@/assets/newExam/sheet/icon_clear.png" alt="" />
            阅卷教师尚未清空！
          </template>
          <el-button type="text" @click="linkTo(2)">前往阅卷分工</el-button>
        </div>
        <div class="item-sheet">
          <template v-if="indexStatus.markStatus === 0">
            <img src="@/assets/newExam/sheet/icon_check.png" alt="" />
            <template v-if="indexPaper.sheetMarkType == 1">
              先阅后扫无需处理!
            </template>
            <template v-else> 阅卷进度已清空！</template>
          </template>
          <template v-else>
            <img src="@/assets/newExam/sheet/icon_clear.png" alt="" />
            阅卷进度尚未清空！
          </template>
          <el-button type="text" @click="linkTo(3)">前往阅卷重评</el-button>
        </div>
        <!-- <el-checkbox v-model="checked"></el-checkbox> -->
        <p style="color: rgb(254, 95, 89)">确认清除全部框选痕迹</p>
        <p style="color: rgb(254, 95, 89)">
          请不要在识别答题卡的过程中更换模版,否则可能会导致更换失败!
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="linkCheck()">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="更换答题卡"
      :visible.sync="dialogVisibleCheck"
      width="384px"
      :close-on-click-modal="false"
    >
      <div class="check-box">
        <el-radio v-model="checkType" :label="1" border>
          新建三方答题卡
        </el-radio>
        <el-radio v-model="checkType" :label="2" border>复用答题卡</el-radio>
      </div>
      <template v-if="checkType == 2">
        <div class="sub-box select-list">科目：{{ indexPaper.label }}</div>
        <div class="select-list">
          <p>答题卡类型：</p>
          <el-select
            v-model="searchSheet.type"
            placeholder="请选择"
            @change="checkPage()"
          >
            <el-option
              v-for="item in sheetTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-list">
          <p>选 择 模 板：</p>
          <el-select
            v-model="sheetUuid"
            class="name-select"
            placeholder="请选择"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in sheetIdList"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCheck = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="checkPaper()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  exampapersheetPage,
  exampapersheetDuplicate,
  checkClear,
  exampapersheetClear,
} from "@/core/api/exam/sheet";
export default {
  name: "ChangeSheet",
  data() {
    return {
      dialogVisible: false,
      dialogVisibleCheck: false,
      checked: true,
      btnLoading: false,
      checkType: 1,
      indexPaper: {},
      indexStatus: {},
      loading: false,
      sheetIdList: [],
      sheetTypeList: [],
      sheetUuid: "",
      searchSheet: { type: "" },
    };
  },
  created() {},
  methods: {
    checkPaper() {
      if (this.checkType == 1) {
        this.dialogVisibleCheck = false;
        this.$parent.goLink();
      } else {
        this.setPaperUuid();
      }
    },
    setPaperUuid() {
      let data = {
        sheetUuid: this.sheetUuid,
        examPaperId: this.indexPaper.value,
      };
      this.btnLoading = true;
      exampapersheetDuplicate(data)
        .then(() => {
          this.$message({
            message: "使用成功！",
            type: "success",
          });
          this.$parent.getObj();
          this.dialogVisibleCheck = false;
          setTimeout(() => {
            this.btnLoading = false;
          }, 500);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    checkPage() {
      this.sheetUuid = "";
      this.exampapersheetPage();
    },
    linkTo(type) {
      if (type == 2 || type == 3) {
        if (this.indexPaper.sheetMarkType == 1) {
          this.$message({
            showClose: true,
            message: "先阅后扫不用处理!",
            type: "warning",
          });
          return;
        }
      }
      if (type == 1) {
        let data = {
          examId: this.$route.query.examId,
          paperId: this.indexPaper.value,
        };
        this.$router.push({
          path: "/exam/ExamPaperAnswer",
          query: data,
        });
      }
      if (type == 2) {
        let data = {
          examId: this.$route.query.examId,
          paperId: this.indexPaper.value,
          examType: this.$parent.examInformation.examType,
        };
        this.$router.push({
          path: "/exam/ExamCorrectTask",
          query: data,
        });
      }
      if (type == 3) {
        let data = {
          examId: this.$route.query.examId,
          examPaperId: this.indexPaper.value,
          subjectId: this.indexPaper.subjectId,
          examType: this.$parent.examInformation.examType,
          getType: this.indexPaper.getType,
          markType: this.indexPaper.markType,
          routerName: "examlist",
        };
        let url = "MarkingReappraisal";
        if (this.$parent.examInformation.examType == 1) {
          url = "union" + url;
          data.routerName = "/exam/unionExam";
        }
        this.$router.push({
          name: url,
          query: data,
        });
      }
    },
    exampapersheetPage() {
      this.sheetIdList = [];
      this.loading = true;
      let data = this.searchSheet;
      let data1 = {
        size: 50,
        current: 1,
      };
      data.isValid = 1;
      data.subjectId = this.$route.query.subjectId;
      exampapersheetPage(data, data1)
        .then((res) => {
          this.sheetIdList = res.data.data.records;
          let sheetId = this.paperList.filter(
            (i) => i.subjectId == this.indexSubjectId
          )[0].sheetUuid;
          this.sheetIdList = this.sheetIdList.filter(
            (item) => item.uuid != sheetId
          );

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.searchSheet.name = query;
        this.exampapersheetPage();
      } else {
        this.sheetIdList = [];
      }
    },
    init(val) {
      this.sheetTypeList = this.$parent.sheetTypeList;
      this.indexPaper = val;
      checkClear({ examPaperId: this.indexPaper.value }).then((res) => {
        this.indexStatus = res.data.data;
        if (
          this.indexStatus.allocateStatus != 0 ||
          this.indexStatus.answerStatus != 0 ||
          this.indexStatus.markStatus != 0
        ) {
          this.dialogVisible = true;
        } else {
          // this.dialogVisibleCheck = true;
          this.dialogVisible = true;
        }
      });
    },
    linkCheck() {
      if (
        this.indexStatus.allocateStatus != 0 ||
        this.indexStatus.answerStatus != 0 ||
        this.indexStatus.markStatus != 0
      ) {
        this.$message({
          showClose: true,
          message: "请先完成以上步骤!",
          type: "error",
        });
        return;
      }
      this.btnLoading = true;
      exampapersheetClear({ examPaperId: this.indexPaper.value })
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.dialogVisibleCheck = true;
          this.$parent.getObj();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.changeSheet {
  .el-radio {
    margin-bottom: 12px;
  }
  .select-list {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .el-select {
      width: 100%;
    }
    .name-select {
      width: calc(100% - 90px);
    }
    p {
      width: 90px;
      margin-bottom: 0;
      text-align: right;
      flex-shrink: 0;
    }
  }
  .check-box {
    display: flex;
    justify-content: space-between;
  }
  .sub-box {
    color: #0a1119;
    font-weight: bold;
  }
  .item-sheet-box {
    .item-sheet {
      margin-bottom: 12px;
    }
  }
}
</style>
